body {
  font-family: 'Poppins', sans-serif;
  background-color: white;
  color: #333;
  margin: 0;
  padding: 0;
}

h1, h2 {
  font-family: 'Poppins', sans-serif;
  color: #ff6f61; /* Coral */
}

input, select, textarea {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid lightpink;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff6f61; /* Coral */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #e65b54; /* Darker Coral */
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}