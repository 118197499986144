.preferences-container {
    text-align: center;
    padding: 50px;
  }
  
  input, select, textarea {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid lightpink;
    border-radius: 5px;
  }
  label {
    margin-right: 10px;
  }
  .btn-submit {
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff6f61; /* Coral */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-submit:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (max-width: 768px) {
    label {
        margin-right: 0px;
      }
  }