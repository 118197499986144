.home-container {
  text-align: center;
  padding: 50px;
  max-width: 1200px;
  margin: auto;
}

.home-container h1 {

  margin-bottom: 20px;
  font-size:4.5rem;
}

.home-container p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.main-container {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.btn-plan {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #ff6f61; /* Coral */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 40px;
}

.btn-plan:hover {
  background-color: #e65b54; /* Darker Coral */
}

.info-sections {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  margin-right: 20;
}

.info-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-right: 30px;
  padding: 20px;
  border: 1px solid lightpink;
  border-radius: 10px;
}

.most-right {
  margin-right: 0;
}

.info-section img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.info-section h2 {
  color: #ff6f61; /* Coral */
  margin-bottom: 10px;
}

.info-section p {
  margin: 0;
}
.cover-image {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

@media (max-width: 768px) {
  .home-container {
    padding: 20px;
  }

  .home-container h1 {
    font-size:auto;
}

  .main-container {
    height: auto;
  }
  .info-sections {
    flex-direction: column;
  }
  .info-section {
    flex-direction: column;
    text-align: left;
    margin-right: 0;
  }

  .info-section img {
    margin-bottom: 20px;
  }
}
