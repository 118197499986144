.results-container {
    text-align: center;
    padding: 50px;
    max-width: 800px;
    margin: auto;  
}

.activity-card {
  margin-bottom: 20px;
  padding: 50px;
  padding-top: 25px;
  border: 1px solid lightpink;
  border-radius: 10px;
  background-color: #ffffff; /* Light Pink */
}

.activity-card h3{
  color: #ff6f61;
}
.btn-review {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff6f61; /* Coral */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.btn-review:hover {
  background-color: #e65b54; /* Darker Coral */
}

@media (max-width: 768px) {
  .results-container {
    padding: 20px;
  }
  .activity-card {
  padding: 20px;
  }
}
