.review-container {
    text-align: center;
    padding: 50px;
  }
  
  textarea {
    width: 100%;
    max-width: 400px;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid lightpink;
    border-radius: 5px;
  }

  label{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-submit {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #ff6f61; /* Coral */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-submit:hover {
    background-color: #e65b54; /* Darker Coral */
  }
  
  @media (max-width: 768px) {
    .review-container {
      padding: 20px;
    }
  }